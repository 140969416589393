import MuiButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Button.style";

type variants = "outlined" | "contained";
type buttonTypes =
  | "primary"
  | "secondary"
  | "danger"
  | "success"
  | "warning"
  | "default";
export type sizes = "small" | "medium" | "large";

export interface ComponentProps
  extends React.ComponentPropsWithoutRef<"button"> {
  /**
   * Optional className to apply to the component
   */
  className?: string;
  /**
   * The variant of button
   */
  variant?: variants;
  /**
   * Is the button disabled
   */
  disabled?: boolean;
  /**
   * Function to call when the button is clicked
   */
  onClick?: (event?: any) => void;
  /**
   * Display a loading indicator
   */
  loading?: boolean;
  /**
   * Style of the button
   */
  buttonType?: buttonTypes;
  /**
   * Sets the button to be round
   */
  rounded?: boolean;
  /**
   * Sets the button to be round
   */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  /**
   * Optional onBlur function to call when the button loses focus
   */
  onBlur?: () => void;
  /**
   * Size of the button
   */
  size?: sizes;
}

export type Ref = HTMLButtonElement;
/**
 * Button component
 * Wrapper for the button imported component
 */
const Button = React.forwardRef<Ref, ComponentProps>(
  (
    {
      children,
      className,
      variant = "contained",
      disabled,
      onClick,
      onBlur,
      size = "medium",
      loading,
      rounded,
      type = "button",
      buttonType,
    },
    ref
  ) => {
    return (
      <MuiButton
        variant={variant}
        color="primary"
        className={classes(
          className,
          style.component,
          rounded && style.rounded,
          buttonType === "primary" && style.colored,
          buttonType === "secondary" && style.secondary
        )}
        disabled={disabled}
        onClick={onClick}
        onBlur={onBlur}
        size={size}
        ref={ref}
        startIcon={loading && <CircularProgress color="inherit" size={20} />}
        type={type}
      >
        {children}
      </MuiButton>
    );
  }
);

export { Button };
