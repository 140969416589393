import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    display: "flex",
    alignItems: "center",
    fontSize: Style.text.fontSize.small,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.smallMed,
    }
  )
);

export const product = s({
  flex: 3,
  display: "flex",
  alignItems: "center",
});

export const close = s({
  flex: "0 0 30px",
  textAlign: "center",
  $nest: {
    "& svg": {
      fontSize: 20,
      fontWeight: 700,
    },
  },
  marginRight: 10,
});

export const image = s(
  {
    flex: `0 0 120`,
    $nest: {
      "& img": {
        width: 120,
        height: 100,
      },
    },
    marginRight: 20,
  },
  media(
    {
      maxWidth: Style.screensize.smMax,
    },
    { display: "none" }
  )
);

export const title = s({
  flex: 1,
  paddingRight: 15,
});

export const section = s({
  flex: 1,
});

export const total = s({
  flex: 1,
  $nest: {
    "&:last-child": {
      textAlign: "right",
    },
  },
});

export const quantityWrapper = s({
  display: "flex",
  flex: 1,
  justifyContent: "flex-start",
});

export const quantity = s({
  fontSize: Style.text.fontSize.xl,
  fontWeight: 600,
});
