import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  wrapperClassName?: string;
  /**
   * current value
   */
  value: string;
  /**
   * options
   */
  values: string[];
  /**
   * label
   */
  label: string;
  /**
   * fires on new option selected
   */
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
}
/**
 * Select component
 * Standard MUI Select Wrapper
 */
const Select: React.FC<ComponentProps> = ({
  wrapperClassName,
  value,
  values,
  label,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <div className={wrapperClassName}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <MuiSelect labelId={label} id={label} value={value} onChange={onChange}>
          {values.map((val) => (
            <MenuItem value={val} key={val}>
              {val}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </div>
  );
};

Select.displayName = "Select";

export { Select };
