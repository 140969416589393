import { Actions, ActionTypes } from "../actions";

export interface AuthStore {
  uid?: string;
  displayName?: string;
  inProgress: boolean;
  errorMessage?: string;
}

export const initialState: AuthStore = {
  inProgress: false,
};

export const auth = (
  state: AuthStore = initialState,
  action: Actions
): AuthStore => {
  switch (action.type) {
    case ActionTypes.REQUEST_AUTH: {
      return {
        inProgress: true,
      };
    }
    case ActionTypes.REQUEST_AUTH_SUCCESS: {
      return { ...action.payload, inProgress: false };
    }
    case ActionTypes.REQUEST_AUTH_FAILURE: {
      return {
        ...initialState,
        errorMessage: action.payload.message,
      };
    }
    case ActionTypes.LOGIN_STARTED:
    case ActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};
